<template>
  <div class="container">
    <ContentHeader
      v-bind:title="content_header.title"
      v-bind:subTitle="content_header.subTitle"
    />
    <div class="page__body">
      <div class="page__body__header">
        <div class="row">
          <!--<div class="col-6" v-if="!isLoading">
            <label
              for="text"
              class="has-checkbox page__body__header__label text--capital"
              >Show deactivated banks
              <input
                type="checkbox"
                id="text"
                v-model="showDeactivated"
                @click="switchShowDeactivated()"
                class="form-"
              />
              <span class="checkmark"></span>
            </label>
          </div>-->
          <!--<div class="col-6" v-if="isLoading"></div> -->
          <div class="col-6">
            <button
              type="button"
              data-toggle="modal"
              data-target="#newBankModal"
              @click="toggleAddBankModal"
              class="
                button
                page__body__header__button
                button--grey
                text--capital
              "
              v-if="userCanCreateBank"
            >
              <span class="d-inline-flex mr-2">add new bank</span>
              <ion-icon name="add-outline" class="text--medium"></ion-icon>
            </button>
          </div>
        </div>
      </div>
      <table class="customtable w-100">
        <thead>
          <tr>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 30%"
            >
              bank name
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 20%"
            >
              sort code
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 20%"
            >
              NIBBS Bank Code
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              Status
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              actions
            </th>
          </tr>
        </thead>

        <tbody v-if="!banks.data">
          <tr>
            <td colspan="4">
              <div class="d-flex align-items-center justify-content-center p5">
                <div
                  class="spinner-border"
                  style="width: 3rem; height: 3rem"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <span
                  class="text--black text--600 text--medium d-inline-flex ml-4"
                  >Please wait</span
                >
              </div>
            </td>
          </tr>
        </tbody>

        <tbody v-else-if="this.banks.data != ''">
          <tr v-for="(bank, index) in this.banks.data" :key="index">
            <td style="width: 30%">
              <div class="align-self-center">
                <span
                  class="table__body__text table__text py-1 d-block text--upper"
                  style="word-break: break-word"
                >
                  {{ bank.name }}
                </span>
              </div>
            </td>
            <td style="width: 20%">
              <span
                class="
                  table__body__text table__text
                  text--capital text--success
                "
                style="word-wrap: anywhere"
                >{{ bank.sortCode }}</span
              >
            </td>
            <td style="width: 20%">
              <span class="table__body__text table__text text--capital" style="word-wrap: anywhere">
                {{ bank.nibssBankCode }}
              </span>
            </td>
            <td style="width: 15%">
              <span
                :class="[
                  bank.active ? 'text--success' : 'text-danger',
                  'table__body__text table__text text--capital',
                ]"
              >
                {{ bank.active ? "Activated" : "Deactivated" }}
              </span>
            </td>
            <td style="width: 15%; padding-right: 0 !important;">
              <div class="align-self-center dropdown">
                <button
                  class="
                    button button--grey
                    text--capital text--small
                    d-flex
                    align-items-center
                  "
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  action
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    href="#"
                    class="dropdown-item text--capital"
                    type="button"
                    data-toggle="modal"
                    @click="toggleEditBankModal(index)"
                    v-if="userCanEditBank"
                    >Edit</a
                  >
                  <a
                    v-if="bank.active && userCanDeactivateBank"
                    class="dropdown-item text--capital"
                    type="button"
                    data-toggle="modal"
                    @click="openActivationConfirmation(bank)"
                  >
                    <span class="text-danger">deactivate</span>
                  </a>
                  <a
                    v-if="!bank.active && userCanActivateBank"
                    class="dropdown-item text--capital"
                    type="button"
                    data-toggle="modal"
                    data-target="#activationConfirmModal"
                    @click="openActivationConfirmation(bank)"
                  >
                    <span class="text--success">activate</span>
                  </a>

                  <a
                    class="dropdown-item text--danger"
                    type="button"
                    data-toggle="modal"
                    v-if="userCanDeleteBank"
                    @click="deleteBank(index)"
                  >
                    <span class="text-danger">Delete</span>
                  </a>
                </div>
              </div>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr>
            <td colspan="12">
              <div
                class="
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                  p5
                "
              >
                <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                <span
                  class="d-block text--danger text--600 text--medium mt-3 mb-5"
                  >No data available</span
                >
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <Pagination
        :allItems="banks"
        :size="size"
        @update-created-task="getUpdatedBanks"
        @change-page-index="changePageIndex"
      />
    </div>

    <!-- Add Bank Modal -->
    <createBank
      :show="newBankModal"
      @close="closeAddBankModal"
      @error-submitting-form="alertWarning"
      @error-from-endpoint="alertError"
      @successfully-created-bank="alertSuccess"
    />

    <editBank
      :show="editBankModal"
      :selectedBank="selectedBank"
      @close="closeEditBankModal"
      @error-submitting-form="alertWarning"
      @error-from-endpoint="alertError"
      @successfully-updated-bank="alertSuccess"
    />

    <DeleteModal :show="openActivate" @close="closeConfirmationModal">
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              Are you sure you want to {{ activationBankType }} this bank?
            </h5>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="
                  button button--lg
                  modal__confirm__button modal__confirm__button--cancel
                  mr-2
                "
                @click="closeConfirmationModal"
              >
                cancel
              </button>
              <button
                data-dismiss="#confirmModal"
                class="
                  button button--lg
                  modal__confirm__button modal__confirm__button--delete
                  ml-2
                  save-changes
                "
                @click="closeConfirmationModal"
                v-if="isActivating"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="
                  button button--lg
                  modal__confirm__button modal__confirm__button--delete
                  ml-2
                  save-changes
                "
                @click="activateBank"
                v-else
              >
                {{ activationBankType }}
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <DeleteModal :show="openDelete" @close="closeDeleteModal">
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              Are you sure you want to delete this bank
            </h5>
            <h6 class="my-4">
              When you delete this bank from your list, you won't be able to
              access it again,
            </h6>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="
                  button button--lg
                  modal__confirm__button modal__confirm__button--cancel
                  mr-2
                "
                @click="closeDeleteModal"
              >
                cancel
              </button>
              <button
                data-dismiss="#confirmModal"
                class="
                  button button--lg
                  modal__confirm__button modal__confirm__button--delete
                  ml-2
                  save-changes
                "
                @click="closeDeleteModal"
                v-if="isDeleting"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="
                  button button--lg
                  modal__confirm__button modal__confirm__button--delete
                  ml-2
                  save-changes
                "
                @click="deleteBankFromList"
                v-else
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Role } from "@/core/services/role.js";
// import ApiService from "@/core/services/api.service";

import { mapState } from "vuex";
import {
  GET_ALL_BANKS,
  DELETE_BANK,
  DEACTIVATE_BANK,
} from "@/core/services/store/banking.module";

export default Vue.extend({
  name: "BankingIndex",
  components: {
    createBank: () =>
      import(
        /* WebpackChunkName: "CreateBank" */ "@/view/pages/generalSetup/banking/addBank.vue"
      ),
    editBank: () =>
      import(
        /* WebpackChunkName: "CreateBank" */ "@/view/pages/generalSetup/banking/editBank.vue"
      ),
    DeleteModal: () =>
      import(
        /* WebpackChunkName: "DeleteModal" */ "@/components/DeleteModal/_base-delete-modal.vue"
      ),

    Pagination: () =>
      import(
        /* WebpackChunkName: "Pagination" */ "@/components/UI/pagination.vue"
      ),
  },
  data: function () {
    return {
      content_header: {
        title: "Banks",
        subTitle: "",
      },
      newBankModal: false,
      editBankModal: false,
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      pages: 1,
      size: 10,
      selectedBank: {},
      openDelete: false,
      isDeleting: false,
      openActivate: false,
      activationBank: null,
      activationBankType: "",
      isActivating: false,
      showDeactivated: false,
    };
  },

  computed: {
    ...mapState({
      banks: (state) => state.banking.allBanks,
      user: (state) => state.auth.user,
    }),
    userCanCreateBank() {
      const bank = this.user.scopes.find(item => item.scopeName === Role.GeneralSetup);
      const { permissionsList } = bank;
      return permissionsList.CanCreateBank;
    },
    userCanEditBank() {
      const bank = this.user.scopes.find(item => item.scopeName === Role.GeneralSetup);
      const { permissionsList } = bank;
      return permissionsList.CanEditBank;
    },
    userCanDeleteBank() {
      const bank = this.user.scopes.find(item => item.scopeName === Role.GeneralSetup);
      const { permissionsList } = bank;
      return permissionsList.CanDeleteBank;
    },
    userCanActivateBank() {
      const bank = this.user.scopes.find(item => item.scopeName === Role.GeneralSetup);
      const { permissionsList } = bank;
      return permissionsList.CanActivateBank;
    },
    userCanDeactivateBank() {
      const bank = this.user.scopes.find(item => item.scopeName === Role.GeneralSetup);
      const { permissionsList } = bank;
      return permissionsList.CanDeactivateBank;
    },
  },

  methods: {
    toggleAddBankModal() {
      this.newBankModal = !this.newBankModal;
    },
    closeAddBankModal() {
      this.newBankModal = false;
    },
    toggleEditBankModal(index) {
      this.editBankModal = !this.editBankModal;
      this.selectedBank = this.banks.data[index];
    },
    closeEditBankModal() {
      this.editBankModal = false;
      this.selectedBank = {};
    },
    getAllBanks() {
      this.$store.dispatch(GET_ALL_BANKS, {
        pageindex: this.pages,
        pagesize: this.size,
      });
    },
    getUpdatedBanks(value) {
      this.size = value;
      this.getAllBanks();
    },
    changeCurrentPage(pages = null) {
      if (pages === "next") {
        let nextPage = this.banks.hasNext
          ? this.banks.currentPage + 1
          : 0;
        this.$store.dispatch(GET_ALL_BANKS, {
          pageindex: nextPage,
          pagesize: this.size,
        });
      } else if (pages === "previous") {
        let nextPage = this.banks.hasPrevious
          ? this.banks.currentPage - 1
          : 0;
        this.$store.dispatch(GET_ALL_BANKS, {
          pageindex: nextPage,
          pagesize: this.size,
        });
      } else if (pages !== null) {
        this.$store.dispatch(GET_ALL_BANKS, {
          pageindex: pages,
          pagesize: this.size,
        });
      }
    },
    changePageIndex(value) {
      this.changeCurrentPage(value);
    },
    closeDeleteModal() {
      this.openDelete = false;
      this.selectedBank = {};
    },
    deleteBank(index) {
      this.selectedBank = this.banks.data[index];
      this.openDelete = true;
    },
    deleteBankFromList() {
      try {
        this.isDeleting = true;
        let accountKey = this.selectedBank.bankKey;
        this.$store
          .dispatch(DELETE_BANK, { accountKey })
          .then((resp) => {
            this.isDeleting = false;
            this.openDelete = false;
            this.alertSuccess(resp.data.message);
            this.getAllBanks();
            this.selectedBank = {};
          })
          .catch((error) => {
            this.isDeleting = false;
            this.alertError(error.response.message);
          });
      } catch (e) {
        this.alertError(e);
        this.isDeleting = false;
      }
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
      this.getAllBanks();
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    openActivationConfirmation(bank) {
      this.activationBank = bank;
      this.openActivate = true;
      if (bank.active) return (this.activationBankType = "Deactivate");
      this.activationBankType = "Activate";
    },
    closeConfirmationModal() {
      this.openActivate = false;
      this.activationBank = {};
    },
    activateBank() {
      // const bankKey = this.activationBank.bankKey;
      // console.log(bankKey);
      if (this.activationBank.active) {
        this.isActivating = true;
        this.$store
          .dispatch(DEACTIVATE_BANK, {
            bankKey: this.activationBank.bankKey,
            status: false,
          })
          .then((res) => {
            this.isActivating = false;
            this.closeConfirmationModal();
            this.alertSuccess(res.data.message);
            this.getAllBanks();
          })
          .catch((error) => {
            this.isActivating = false;
            this.alertError(error);
          });
      } else {
        this.isActivating = true;
        this.$store
          .dispatch(DEACTIVATE_BANK, {
            bankKey: this.activationBank.bankKey,
            status: true,
          })
          .then((res) => {
            this.isActivating = false;
            this.closeConfirmationModal();
            this.alertSuccess(res.data.message);
            this.getAllBanks();
          })
          .catch((error) => {
            this.isActivating = false;
            this.alertError(error.response.data.message);
          });
      }
    },
    switchShowDeactivated() {
      this.showDeactivated = !this.showDeactivated;
      if (this.showDeactivated) {
        this.fetchModel.branchState = null;
      } else {
        this.fetchModel.branchState = "Active";
      }
      this.getBranches();
    },
  },
  async created() {
    this.getAllBanks();
  },
});
</script>
